import React, { useState, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  getFaceRcognitionQR,
  checkFaceRecognitionStatus,
} from "../../../api/apiCall";

function FacialRecognitionApproved() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const [qrCodeApplicantSVG, setQrCodeApplicantSVG] = useState("");
  const [qrCodeGuardianSVG, setQrCodeGuardianSVG] = useState("");

  useEffect(() => {
    handleGetApplicantFaceRecognitionQR();
    handleGetGuardianFaceRecognitionQR();

    const intervalId = setInterval(() => {
      handleCheckFaceRecognitionStatus();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleGetApplicantFaceRecognitionQR = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        for: "APPLICANT",
      };

      const response = await getFaceRcognitionQR(data);
      if (response.status === 200) {
        setQrCodeApplicantSVG(response.data);
      }
    } catch (error) {
      console.error("Failed to get face recognition QR for applicant: ", error);
    }
  };

  const handleGetGuardianFaceRecognitionQR = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        for: "GUARDIAN",
      };

      const response = await getFaceRcognitionQR(data);
      if (response.status === 200) {
        setQrCodeGuardianSVG(response.data);
      }
    } catch (error) {
      console.error("Failed to get face recognition QR fro guardian: ", error);
    }
  };

  const handleCheckFaceRecognitionStatus = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      };

      const response = await checkFaceRecognitionStatus(data);
      if (response.data.message === "Face Recognition Done!") {
        navigate("/interview");
        return;
      }
    } catch (error) {
      console.log("Error checking face recognition status: ", error);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50">
      <div className="w-full max-w-[750px] m-auto bg-white rounded-lg p-6 border border-gray-200">
        <div className="flex flex-col px-6 w-full max-md:px-5 justify-between">
          <div className="flex items-center justify-between mb-4">
            <div
              className="flex justify-center items-center gap-1 cursor-pointer text-blue-500 text-base font-medium"
              onClick={() => navigate(-1)}
            >
              <FaChevronLeft /> Back
            </div>
            <img
              src="/FullIDCheckLogo.svg"
              alt="FullIDCheck Logo"
              className="h-10"
            />
          </div>
          <hr />
          <div className="mt-7 text-2xl text-center font-bold tracking-tight leading-7 text-blue-900">
            Facial Recognition
          </div>
          <div className="flex justify-between items-center mt-6 w-full max-md:flex-col max-md:items-center max-md:mt-10">
            <div className="flex flex-col w-[48%] max-md:w-full max-md:mb-8">
              <div className="text-lg font-bold text-center text-slate-400">
                Step 1
              </div>
              <div className="mt-4 mb-3 px-5 text-base leading-5 text-center text-slate-400">
                Scan this code using your mobile phone to start the facial
                scanning feature for{" "}
                <span className="font-bold">APPLICANT</span>
              </div>
              <div className="flex justify-center items-center p-2.5">
                <div className="border-8 border-teal-300" title="QR Code">
                  <div
                    className="w-48 h-48"
                    dangerouslySetInnerHTML={{ __html: qrCodeApplicantSVG }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[48%] max-md:w-full">
              <div className="text-lg font-bold text-center text-slate-400">
                Step 2
              </div>
              <div className="mt-4 mb-3 px-5 text-base leading-5 text-center text-slate-400">
                Scan this code using your mobile phone to start the facial
                scanning feature for{" "}
                <span className="font-bold">PARENT / GUARDIAN</span>
              </div>
              <div className="flex justify-center items-center p-2.5">
                <div className="border-8 border-teal-300" title="QR Code">
                  <div
                    className="w-48 h-48"
                    dangerouslySetInnerHTML={{ __html: qrCodeGuardianSVG }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacialRecognitionApproved;
