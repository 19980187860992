import StripePayment from "./StripePayment";
function Stripe() {
  return (
    <div className="bg-[#F9FAFB] py-14 h-full">
      <StripePayment />
    </div>
  );
}

export default Stripe;
