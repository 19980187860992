import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NotLoggedInNavigations from "./routes/NotLoggedInNavigations";
import LoggedIn from "./routes/LoggedIn";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "./actions";
import axios from "axios";
import { getAddress } from "./api/apiCall";

function App() {
  const isLoggedIn = useSelector((state) => state.value.isLoggedIn);
  const isLoading = useSelector((state) => state.value.isLoading);
  const dispatch = useDispatch();

  // Total session duration: 30 minutes (10 min idle + 20 min confirmation)
  const TOTAL_SESSION_DURATION = 20 * 60 * 1000; // 20 minutes in milliseconds
  const IDLE_TIMEOUT = 10 * 60 * 1000; // 10 minutes idle before modal
  
  const [remainingSessionTime, setRemainingSessionTime] = useState(TOTAL_SESSION_DURATION);
  const [showExtendSessionModal, setShowExtendSessionModal] = useState(false);
  const lastActivityTime = useRef(Date.now());
  const activityEvents = ['mousemove', 'keydown', 'scroll', 'click'];

  // Check login status from localStorage and perform redirect if needed
  useEffect(() => {
    const checkLocalStorage = async () => {
      const isLoggedInLocal = JSON.parse(localStorage.getItem("isLoggedIn"));
      await dispatch(setIsLoggedIn(isLoggedInLocal || false));

      if (isLoggedInLocal && window.location.pathname === "/") {
        window.location.href = "/dashboard/edit-profile?tab=overview";
      }
    };

    checkLocalStorage();

    if (isLoading) {
      document.body.classList.add("overflow-hidden");
      return () => document.body.classList.remove("overflow-hidden");
    }
  }, [dispatch, isLoading]);

  // Track user activity
  useEffect(() => {
    const updateLastActivityTime = () => {
      lastActivityTime.current = Date.now();
    };

    // Add event listeners for user activity
    activityEvents.forEach(event => 
      document.addEventListener(event, updateLastActivityTime)
    );

    return () => {
      // Remove event listeners
      activityEvents.forEach(event => 
        document.removeEventListener(event, updateLastActivityTime)
      );
    };
  }, []);

  // Geolocation API to fetch user's address
  useEffect(() => {
    const LOCATIONIQ_API_KEY = "pk.4cd67ac67c0cfee819d0f453ae533eae";

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getLocationData(latitude, longitude);
      },
      (err) => {
        console.error("Failed to retrieve location.", err);
      }
    );

    const getLocationData = async (lat, lon) => {
      try {
        // const response = await axios.get(
        //   `https://us1.locationiq.com/v1/reverse.php?key=${LOCATIONIQ_API_KEY}&lat=${lat}&lon=${lon}&format=json`
        // );
        const data = {
          lat: lat,
          lon: lon,
        };

        const response = await getAddress(data);
        localStorage.setItem("userAddress", JSON.stringify(response.data.address));
      } catch (error) {
        console.error("Error fetching location data: ", error);
      }
    };
  }, []);

  // Session timer logic
  useEffect(() => {
    if (!isLoggedIn) return;

    const timer = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivityTime.current;

      setRemainingSessionTime(prevTime => {
        // If inactive for 20 minutes, show modal
        if (timeSinceLastActivity >= IDLE_TIMEOUT && !showExtendSessionModal) {
          setShowExtendSessionModal(true);
        }

        const newTime = prevTime - 1000;
        if (newTime <= 0) {
          clearInterval(timer);
          handleLogout();
          return 0;
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isLoggedIn, showExtendSessionModal]);

  // Logout the user
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    dispatch(setIsLoggedIn(false));
    window.location.href = "/login";
  };

  // Extend the session
  const handleStayLoggedIn = () => {
    setRemainingSessionTime(TOTAL_SESSION_DURATION);
    setShowExtendSessionModal(false);
    lastActivityTime.current = Date.now();
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-9999999 bg-white/75">
          <div className="relative flex justify-center items-center">
            <div className="absolute animate-spin rounded-full h-24 w-24 max-md:w-14 max-md:h-14 max-sm:w-12 max-sm:h-12 border-t-4 max-sm:border-t-2 border-b-4 max-sm:border-b-2 border-devRegBlue"></div>
            <img
              src="/lock.svg"
              className="rounded-full h-14 w-14 max-md:w-10 max-md:h-10 max-sm:w-7 max-sm:h-7"
              alt="Loading"
            />
          </div>
          <p className="mt-9 max-sm:mt-4 text-lg max-sm:text-sm font-semibold text-devRegBlue animate-pulse">
            Loading...
          </p>
        </div>
      )}

      {/* Extend Session Modal */}
      {showExtendSessionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="session-modal bg-white rounded-lg p-6 max-w-sm w-full shadow-lg">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Session Expiry Warning</h2>
            <p className="text-gray-600 mb-4">
              Your session will expire in {Math.ceil(remainingSessionTime / 60000) <= 1 ? 
              Math.ceil(remainingSessionTime / 1000) + ' second(s)' : 
              Math.ceil(remainingSessionTime / 60000) + ' minute(s)'}.
            </p>
            <p className="text-gray-600 mb-6">Do you want to stay logged in?</p>
            <div className="button-container flex justify-end mt-4 gap-1">
              <button
                onClick={handleLogout}
                style={{
                  padding: "0.5rem 1.5rem",
                  color: "#1C3775",
                  backgroundColor: "transparent",
                  border: "1px solid #1C3775",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease-in-out",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#1C3775";
                  e.target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "transparent";
                  e.target.style.color = "#1C3775";
                }}
              >
                No
              </button>
              <button
                onClick={handleStayLoggedIn}
                style={{
                  padding: "0.5rem 1.5rem",
                  backgroundColor: "#1C3775",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#0043d3";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#1C3775";
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <Router>
        {isLoggedIn ? <LoggedIn /> : <NotLoggedInNavigations />}
      </Router>
    </>
  );
}

export default App;