import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import NavigationBar from "../component/NavBar";
import DashboardNavbar from "../pages/Dashboard/components/DashboardNavbar";
import HomeMain from "../pages/Basic/Home";
import WhyRegisterMain from "../pages/Basic/WhyRegister";
import BenefitPageMain from "../pages/Basic/BenefitPage";
import Footer from "../component/Footer";
import HowItWorks from "../pages/Basic/HowItWorks";
import AboutUs from "../pages/Basic/AboutUs";
import AboutProfile from "../pages/Basic/AboutProfile";
import FAQMain from "../pages/Basic/Faq";
import CategoryFaqs from "../pages/Basic/CategoryFaqs";
import Viewseemore from "../pages/Basic/Viewseemore";
import ContactMain from "../pages/Basic/Contact";
import RequirementsManagerPos from "../pages/Basic/RequirementsManagerPos";
import PrivacyPolicyMain from "../pages/Basic/Privacy";
import AntibribePolicyMain from "../pages/Basic/AntibribePolicy";
import TermsConditionsMain from "../pages/Basic/TermsAndConditions";
import LoginMain from "../pages/Login/LoginMain";
import ForgotPassMain from "../pages/Login/forgotPassword/ForgotPassMain";
import PasswordSuccess from "../pages/Login/forgotPassword/PassUpdateSuccess";
import QRScan from "../pages/Login/2fa/QRScan";
import OTPVerify from "../pages/Login/2fa/OTPVerify";
import RecoveryCodes from "../pages/Login/2fa/RecoveryCodes";
import TwoFAFromApp from "../pages/Login/2fa/TwoFAFromApp";
import EnterRecoveryCodes from "../pages/Login/2fa/EnterRecoveryCodes";
import EnterCodeAccess from "../pages/Login/accessKey/EnterCodeAccess";
import UploadDocumentsReq from "../pages/Login/accessKey/UploadDocumentsReq";
import UploadSuccess from "../pages/Login/accessKey/UploadSuccess";
import Registration from "../pages/Registration/Registration";
import PersonalInformation from "../pages/Registration/PersonalInformation";
import Stripe from "../pages/Registration/Stripe";
import Thankyou from "../pages/Registration/Thankyou";
import UploadDocuments from "../pages/Registration/UploadDocuments";
import FacialRecognition from "../pages/Registration/FacialRecognition";
import Interview from "../pages/Registration/Interview";
import RegistrationSuccess from "../pages/Registration/RegistrationSuccess";
import GuardianInformation from "../pages/Registration/minorApplicant/GuardianInformation";
import FacialRecognitionApproved from "../pages/Registration/minorApplicant/FacialRecognitionApproved";
import ScanFace from "../pages/Registration/FaceScanner/ScanFace";
import EmailVerified from "../pages/Registration/EmailVerified";
import EmailVerifiedFailed from "../pages/Registration/EmailVerifiedFailed";
import PageNotFound from "../../src/PageNotFound";
import RegistrationDone from "../pages/Registration/RegistrationDone";
import InterviewProcess from "../pages/Registration/InterviewProcess";
import PendingApproval from "../pages/Registration/PendingApproval";
import GoogleAuthCallback from "../pages/Registration/GoogleAuthCallback";
import axios from "axios";

// Move static arrays out of the component
const loginPaths = [
  "/login",
  "/register",
  "/forgot-password",
  "/qr-scan",
  "/otp-verify",
  "/recovery-codes",
  "/2fa-authApp",
  "/enter-recovery-codes",
  "/password-success",
  "/email-verified",
  "/email-verification/failed",
  "/registration-success",
  "/registration-done",
  "/pending-approval",
];

const registrationNavPaths = [
  "/payment",
  "/thankyou",
  "/enter-access-key",
  "/upload-documentsreq",
  "/upload-success",
  "/upload-documents",
  "/face-recognition",
  "/interview-process",
  "/interview",
  "/guardian-information",
  "/personal-information",
  "/face-recognition-minor",
];

const dynamicRegistrationNavPaths = [];

// Protected Route component for authentication
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userHasToken = Boolean(localStorage.getItem("token"));
  return userHasToken ? <Component {...rest} /> : <Navigate to="/login" />;
};

function NotLoggedInNavigations() {
  const location = useLocation();

  // UI state for navigation bars
  const [pageToLogin, setPageToLogin] = useState(false);
  const [showRegistrationNav, setShowRegistrationNav] = useState(false);

  // State for team members fetching
  const [teamMembers, setTeamMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [membersFetched, setMembersFetched] = useState(false);

  // Update navigation bar visibility on route changes
  useEffect(() => {
    const isLoginPage = loginPaths.some((path) =>
      location.pathname.startsWith(path)
    );
    const isRegistrationNavPage = [
      ...registrationNavPaths,
      ...dynamicRegistrationNavPaths,
    ].some((path) => location.pathname.startsWith(path));

    setPageToLogin(isLoginPage || isRegistrationNavPage);
    setShowRegistrationNav(isRegistrationNavPage);
  }, [location.pathname]);

  // Fetch team members only once on component mount
  useEffect(() => {
    if (!membersFetched) {
      const fetchMembers = async () => {
        try {
          setLoadingMembers(true);
          // Fetch data concurrently from both endpoints
          const [response1, response2] = await Promise.all([
            axios.get(`${process.env.REACT_APP_CEO_API_URL}/api/country-manager`),
            axios.get(
              `${process.env.REACT_APP_CEO_API_URL}/api/senior-country-manager`
            ),
          ]);

          // Format member data
          const formatMembers = (data, position) =>
            Array.isArray(data)
              ? data.map((item) => ({
                  ...item,
                  fullname: `${item.first_name} ${item.middle_name} ${item.last_name}`,
                  position,
                }))
              : [];

          setTeamMembers([
            ...formatMembers(response1.data, "Country Manager"),
            ...formatMembers(response2.data, "Sr. Country Manager"),
          ]);
        } catch (error) {
          console.error("Failed to fetch team members: ", error);
        } finally {
          setLoadingMembers(false);
        }
      };

      fetchMembers();
      setMembersFetched(true);
    }
  }, [membersFetched]);

  // Define protected routes
  const protectedRoutes = [
    { path: "/enter-access-key", component: EnterCodeAccess },
    { path: "/upload-documentsreq", component: UploadDocumentsReq },
    { path: "/upload-success", component: UploadSuccess },
    { path: "/personal-information", component: PersonalInformation },
    { path: "/payment", component: Stripe },
    { path: "/thankyou", component: Thankyou },
    { path: "/upload-documents", component: UploadDocuments },
    { path: "/face-recognition", component: FacialRecognition },
    // { path: "/face-scan/:key", component: ScanFace },
    { path: "/interview-process", component: InterviewProcess },
    { path: "/interview", component: Interview },
    { path: "/registration-done", component: RegistrationDone },
    { path: "/guardian-information", component: GuardianInformation },
    { path: "/face-recognition-minor", component: FacialRecognitionApproved },
  ];

  return (
    <>
      {/* Conditionally render the navigation bars */}
      {!pageToLogin && <NavigationBar />}
      {showRegistrationNav && <DashboardNavbar />}

      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/why-register" element={<WhyRegisterMain />} />
        <Route path="/benefits" element={<BenefitPageMain />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route
          path="/about-us"
          element={<AboutUs teamMembers={teamMembers} loading={loadingMembers} />}
        />
        <Route path="/about-profile" element={<AboutProfile />} />
        <Route path="/faq" element={<FAQMain />} />
        <Route path="/faq/category" element={<CategoryFaqs />} />
        <Route path="/faq/category/view" element={<Viewseemore />} />
        <Route path="/face-scan/:key" element={<ScanFace />} />
        <Route path="/contact" element={<ContactMain />} />
        <Route path="/apply-country-manager" element={<RequirementsManagerPos />} />
        <Route path="/privacy" element={<PrivacyPolicyMain />} />
        <Route path="/anti-bribery-policy" element={<AntibribePolicyMain />} />
        <Route path="/terms-and-conditions" element={<TermsConditionsMain />} />
        <Route path="/login" element={<LoginMain />} />
        <Route path="/forgot-password" element={<ForgotPassMain />} />
        <Route path="/password-success" element={<PasswordSuccess />} />
        <Route path="/qr-scan" element={<QRScan />} />
        <Route path="/otp-verify" element={<OTPVerify />} />
        <Route path="/recovery-codes" element={<RecoveryCodes />} />
        <Route path="/2fa-authApp" element={<TwoFAFromApp />} />
        <Route path="/enter-recovery-codes" element={<EnterRecoveryCodes />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/pending-approval" element={<PendingApproval />} />
        <Route path="/email-verification/failed" element={<EmailVerifiedFailed />} />
        {protectedRoutes.map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={<ProtectedRoute component={Component} />}
          />
        ))}
        <Route path="/auth/callback" element={<GoogleAuthCallback />} />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {!pageToLogin && <Footer />}
    </>
  );
}

export default NotLoggedInNavigations;
