import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CiBellOn } from "react-icons/ci";
import { IoIosMenu } from "react-icons/io";
import SidebarMobile from "./SidebarMobile";
import { HiOutlineInbox } from "react-icons/hi2";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { IoPersonOutline, IoTrophyOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { HiMiniChevronRight } from "react-icons/hi2";
import { logout } from "../../../api/apiCall.js";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../../actions";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { RiLogoutCircleRLine } from "react-icons/ri";

function DashboardNavbar() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const [toggleDashboardTab, setToggleDashboardTab] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const avatar = user?.profile_photo_path || "/default-avatar.png";

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
      };

      const response = await logout(data);
      if (response.status === 200) {
        localStorage.clear();
        dispatch(setIsLoggedIn(false));
        navigate("/");
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error("Error logging out: ", error);
      localStorage.clear();
    } finally {
      localStorage.clear();
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    if (toggleDashboardTab) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleDashboardTab]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");

  // Check if the current URL matches the desired path
  const isMessagesTab = location.pathname.startsWith("/dashboard/messages");
  const isRegisterTab = isMessagesTab && tab === "theRegister";
  const isDevregTab = isMessagesTab && tab === "devreg";
  const isAdminTab = isMessagesTab && tab === "admin";

  const isForumTab = location.pathname.startsWith("/dashboard/forum");
  const isSavedPostsTab = location.pathname === "/dashboard/forum/saved-posts";
  const isCategoryTab =
    location.pathname === "/dashboard/forum/category-viewed";
  const isNewPostTab = location.pathname === "/dashboard/forum/new-post";
  const isPostTab = location.pathname.startsWith("/dashboard/forum/post/");

  const isEditProfileTab = location.pathname === "/dashboard/edit-profile";
  const isDownloadCertTab = location.pathname === "/dashboard/certificate";
  const isSecurityTab = location.pathname.startsWith(
    "/dashboard/account-settings"
  );
  const isEarningsAndReferralsTab =
    location.pathname === "/dashboard/earnings-and-referrals";
  const isHelpTab = location.pathname === "/dashboard/help";
  const isTicketTab = location.pathname === "/dashboard/ticketing";

  const isOverviewTab = tab === "overview";
  const isAdvertisementTab = tab === "advertisement-features";
  const isLanguageTab = tab === "language";
  const isWorkTab = tab === "work-history";
  const isPortfolioTab = tab === "portfolio";
  const isSkillsTab = tab === "skills-and-expertise";
  const isEducationTab = tab === "education-certificate";
  const isHonorTab = tab === "honor-and-awards";

  const isChangePasswordTab =
    location.pathname === "/dashboard/account-settings/change-password";
  const isChangeEmailTab =
    location.pathname === "/dashboard/account-settings/change-email";
  const isChangeNumberTab =
    location.pathname === "/dashboard/account-settings/change-number";

  const navigateToTab = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className="border-b border-neutral-300 border-solid">
      {toggleDashboardTab && (
        <div
          className=" w-[100vw] h-[100vh] bg-[#040e1d94] absolute z-[10] min-[768px]:hidden"
          onClick={() => setToggleDashboardTab(!toggleDashboardTab)}
        >
          <SidebarMobile />
        </div>
      )}
      <header className="max-w-[1366px] m-auto flex justify-between items-center max-md:flex-wrap max-md:max-w-full max-sm:px-2 max-sm:py-2 px-8 py-3">
        <div
          className="hamburger-menu px-1 py-1 bg-[#F2F7FF] rounded-xl max-sm:text-[30px] max-md:text-[40px] cursor-pointer min-[768px]:hidden"
          onClick={() => setToggleDashboardTab(!toggleDashboardTab)}
        >
          <IoIosMenu />
        </div>

        <div className="flex item-center gap-14">
          <img
            loading="lazy"
            src="/FullIDCheckLogo.svg"
            alt="Company logo"
            className="my-auto max-w-full w-[220px] cursor-pointer max-sm:w-[170px] max-[378px]:w-[120px] max-md:pl-6"
            onClick={() => {
              navigate("/dashboard/messages");
              window.scrollTo(0, 0);
            }}
          />
          <div className="flex items-center border-l border-gray-200 pl-4 max-[768px]:hidden">
            {isForumTab ? (
              <>
                <HiOutlineChatBubbleLeftRight
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/forum")}
                >
                  Forum
                </span>
                {isSavedPostsTab && (
                  <>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab("/dashboard/forum/saved-posts")
                      }
                    >
                      Saved Posts
                    </span>
                  </>
                )}
                {isNewPostTab && (
                  <>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() => navigateToTab("/dashboard/forum/new-post")}
                    >
                      New Post
                    </span>
                  </>
                )}
                {isPostTab && (
                  <>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2 cursor-pointer"
                    />
                    <span className="text-gray-600 font-light text-[14px] cursor-pointer">
                      Post
                    </span>
                  </>
                )}
                {isCategoryTab && (
                  <>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() => navigateToTab("/dashboard/forum/category")}
                    >
                      Category
                    </span>
                  </>
                )}
              </>
            ) : isEarningsAndReferralsTab ? (
              <>
                <IoTrophyOutline
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() =>
                    navigateToTab("/dashboard/earnings-and-referrals")
                  }
                >
                  Earnings & Referrals
                </span>
              </>
            ) : isEditProfileTab ? (
              <>
                <IoPersonOutline
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/edit-profile")}
                >
                  Professional Profile
                </span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                {isOverviewTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab("/dashboard/edit-profile?tab=overview")
                    }
                  >
                    Overview
                  </span>
                ) : isAdvertisementTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab(
                        "/dashboard/edit-profile?tab=advertisement-features"
                      )
                    }
                  >
                    Advertisement & Features
                  </span>
                ) : isLanguageTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab("/dashboard/edit-profile?tab=language")
                    }
                  >
                    Language
                  </span>
                ) : isWorkTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab("/dashboard/edit-profile?tab=work-history")
                    }
                  >
                    Work History
                  </span>
                ) : isPortfolioTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab("/dashboard/edit-profile?tab=portfolio")
                    }
                  >
                    Portfolio
                  </span>
                ) : isSkillsTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab(
                        "/dashboard/edit-profile?tab=skills-and-expertise"
                      )
                    }
                  >
                    Skills and Expertise
                  </span>
                ) : isEducationTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab(
                        "/dashboard/edit-profile?tab=education-certificate"
                      )
                    }
                  >
                    Education / Certificate
                  </span>
                ) : isHonorTab ? (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab(
                        "/dashboard/edit-profile?tab=honor-and-awards"
                      )
                    }
                  >
                    Honor and Awards
                  </span>
                ) : (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() =>
                      navigateToTab(
                        "/dashboard/edit-profile?tab=download-certificate"
                      )
                    }
                  >
                    Download Certificate
                  </span>
                )}
              </>
            ) : isDownloadCertTab ? (
              <>
                <IoPersonOutline
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/edit-profile")}
                >
                  Professional Profile
                </span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                <span
                  className="text-gray-600 font-light text-[14px] cursor-pointer"
                  onClick={() =>
                    navigateToTab(
                      "/dashboard/edit-profile?tab=download-certificate"
                    )
                  }
                >
                  Download Certificate
                </span>
              </>
            ) : isSecurityTab ? (
              <>
                <IoSettingsOutline
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/account-settings")}
                >
                  Account Settings
                </span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                {isChangePasswordTab ? (
                  <>
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab("/dashboard/account-settings")
                      }
                    >
                      Security Settings
                    </span>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab(
                          "/dashboard/account-settings/change-password"
                        )
                      }
                    >
                      Change Password
                    </span>
                  </>
                ) : isChangeEmailTab ? (
                  <>
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab("/dashboard/account-settings")
                      }
                    >
                      Security Settings
                    </span>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab(
                          "/dashboard/account-settings/change-email"
                        )
                      }
                    >
                      Change Email
                    </span>
                  </>
                ) : isChangeNumberTab ? (
                  <>
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab("/dashboard/account-settings")
                      }
                    >
                      Security Settings
                    </span>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab(
                          "/dashboard/account-settings/change-number"
                        )
                      }
                    >
                      Change Phone Number
                    </span>
                  </>
                ) : (
                  <span
                    className="text-gray-600 font-light text-[14px] cursor-pointer"
                    onClick={() => navigateToTab("/dashboard/account-settings")}
                  >
                    Security Setting
                  </span>
                )}
              </>
            ) : isHelpTab ? (
              <>
                <IoSettingsOutline
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/account-settings")}
                >
                  Account Settings
                </span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                <span
                  className="text-gray-600 font-light text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/help")}
                >
                  Help
                </span>
              </>
            ) : isTicketTab ? (
              <>
                <IoSettingsOutline
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/account-settings")}
                >
                  Account Settings
                </span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                <span
                  className="text-gray-600 font-light text-[14px] cursor-pointer"
                  onClick={() => navigateToTab("/dashboard/ticketing")}
                >
                  Ticket
                </span>
              </>
            ) : isMessagesTab ? (
              <>
                <HiOutlineInbox
                  size={30}
                  color="white"
                  className="bg-[#0082BA] p-1 rounded-full mr-4"
                />
                <span
                  className="font-[600] text-[14px] cursor-pointer"
                  onClick={() =>
                    navigateToTab("/dashboard/messages?tab=devreg")
                  }
                >
                  Messages
                </span>
                {isRegisterTab && (
                  <>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab("/dashboard/messages?tab=theRegister")
                      }
                    >
                      The Register
                    </span>
                  </>
                )}
                {isDevregTab && (
                  <>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab("/dashboard/messages?tab=devreg")
                      }
                    >
                      FullIDCheck
                    </span>
                  </>
                )}
                {isAdminTab && (
                  <>
                    <HiMiniChevronRight
                      size={15}
                      className="text-[14px] mx-2"
                    />
                    <span
                      className="text-gray-600 font-light text-[14px] cursor-pointer"
                      onClick={() =>
                        navigateToTab("/dashboard/messages?tab=admin")
                      }
                    >
                      Admin
                    </span>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="flex item-center justify-center gap-3 px-2">
          <div className="notif-container my-auto relative max-sm:text-[23px] text-[28px]">
            <CiBellOn className="cursor-pointer" />
            <div className="notif-dot bg-[#2AD2C9] w-2 h-2 rounded-full absolute top-1 right-[2px]"></div>
          </div>
          <div
            onClick={toggleDropdown}
            ref={dropdownRef}
            className="profile-container relative flex items-center justify-center gap-3 cursor-pointer"
          >
            <img
              alt="Avatar"
              className="profile-picture w-14 h-14 max-md:w-12 max-md:h-12 max-sm:w-10 max-sm:h-10 rounded-full border-2 border-devRegBlue cursor-pointer"
              src={avatar}
            />

            <div className="name-credit flex items-center justify-center gap-2 leading-5 max-md:hidden">
              <div className="name flex flex-col items-center justify-center font-bold gap-1 text-[#4A4A4A]">
                <div className="first-name">{user?.first_name}</div>
                <div className="first-name">
                  USD&nbsp;{user?.referrals?.total_earned || 0}
                </div>
              </div>

              {dropdownOpen ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>

            {dropdownOpen && (
              <div className="dropdown-menu absolute top-full mt-2 w-48 bg-white shadow-lg rounded-md border border-gray-200 max-md:right-0">
                <ul>
                  <li
                    onClick={handleLogout}
                    className="flex gap-2 items-center px-4 py-2.5 cursor-pointer hover:bg-gray-100"
                  >
                    <RiLogoutCircleRLine color="red" size={20} />
                    Log Out
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export default DashboardNavbar;
