import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setIsLoading } from "../../actions";
import { useDispatch } from "react-redux";
import { data } from "./fullidcheck_professions";
import { getCountries } from "../apiCall";


const useRegistrationForm = () => {
  const [applySuccess, setApplySuccess] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [languages, setLanguages] = useState([]);
  const [interviewLanguage, setInterviewLanguage] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState("");
  const [errors, setErrors] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedJobType, setSelectedJobType] = useState("");
  const [description, setDescription] = useState("");

  const uniqueJobTypes = [...new Set(data.map((job) => job.industry))];

  const professionsForSelectedJobType = data
    .filter((job) => job.industry === selectedJobType)
    .map((job) => job.profession);

    const handleJobTypeChange = (event) => {
      const jobType = event.target.value;
      setSelectedJobType(jobType);
      setSelectedProfession(""); // Clear profession when job type changes
      setDescription(""); // Clear description when job type changes
    };
    
    const handleProfessionChange = (event) => {
      const profession = event.target.value;
      setSelectedProfession(profession);
      const job = data.find((job) => job.profession === profession);
      setDescription(job ? job.description : ""); // Update description based on profession
    };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCountries();
        
        if (response && response.data) {
          const uniqueLanguages = Array.from(
            new Set(
              response.data.flatMap((country) => 
                country.languages ? Object.values(country.languages) : []
              )
            )
          ).sort((a, b) => a.localeCompare(b));
          
          setLanguages(uniqueLanguages);
        } else {
          console.error('No data returned from getCountries()');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    }
    
    fetchData();
  }, []);

  const handleApply = () => {
    const formData = {
      fullname: fullName,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      phone_number: `+${registrationPhoneNumber}`,
      is_part_of_company: 0,
      position: selectedOption,
      profession: selectedProfession,
      industry: '',
      registered_through: "fullidcheck",
      interview_language: interviewLanguage,
    };
    console.log(formData)
    submitRegistrationForm(formData);
  };

  const submitRegistrationForm = async (formData) => {

    dispatch(setIsLoading(true));
    try{

      let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/api/register`,
          headers: {
              'X-Requested-With': 'XMLHttpRequest', 
          },
          withCredentials: true,
          data : formData
      };

      await axios.request(config)
      .then((response) => {
            if(response.status === 201){
               setErrors("");
              localStorage.setItem("id", response.data?.user.id);
              localStorage.setItem("token", response.data?.token);
              localStorage.setItem("fullname", response.data?.user.fullname);
              localStorage.setItem("email", response.data?.user.email);
              localStorage.setItem("phone_number", response.data?.user.phone_number);
              localStorage.setItem("user", JSON.stringify(response.data?.user));
              setApplySuccess(true);
              navigate(`/registration-success`);
            }
      })
      .catch((error) => {
        // Set the error message from response
        setErrors(error.response.data?.message);
      });
    }
    catch(error){
      console.log(error);
      setErrors(error.response.data.message);
    }
    finally{
      dispatch(setIsLoading(false));
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectChangeProfession = (event) => {
    setSelectedProfession(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  const validateEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumber(phoneNumber);
  };

  return {
    applySuccess,
    setCheckboxChecked,
    checkboxChecked,
    selectedOption,
    selectedProfession,
    languages,
    interviewLanguage,
    fullName,
    password,
    confirmPassword,
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    passwordsMatch,
    email,
    isValidEmail,
    registrationPhoneNumber,
    setFullName,
    setPassword,
    setConfirmPassword,
    setIsLengthValid,
    setHasUpperCase,
    setHasLowerCase,
    setHasNumber,
    setHasSpecialChar,
    setPasswordsMatch,
    setEmail,
    setIsValidEmail,
    setRegistrationPhoneNumber,
    setLanguages,
    setInterviewLanguage,
    handleApply,
    handleSelectChange,
    handleSelectChangeProfession,
    handleChange,
    validateEmail,
    handlePhoneNumberChange,
    setApplySuccess,
    errors,
    uniqueJobTypes,
    professionsForSelectedJobType,
    handleProfessionChange,
    handleJobTypeChange,
    description
  };
};

export default useRegistrationForm;
