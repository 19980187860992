import React, { useState, useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function RegistrationDone() {
  const navigate = useNavigate();

  return (
    <div className="bg-[#F9FAFB] py-14 h-screen">
      <div className="h-[30vh] px-10 max-md:px-5">
        <div className="flex justify-center items-center flex-col mx-auto mt-20">
          <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
            <div className="flex items-center justify-center gap-3 max-md:text-center max-md:flex-col">
              <FaRegCheckCircle className="text-[45px] text-[#0082BA]" />
              <div className="flex-1 text-[30px] font-bold text-[#1c3775] max-md:text-[24px]">
                Thank you for your registration!
              </div>
            </div>
            <div className="mt-4 text-center font-normal max-md:text-sm">
              Thank you for registering! We’ve sent an email with a link for scheduling your interview. Please check your inbox (and spam folder, just in case) and follow the instructions provided. If you have any questions or don’t receive the email within a few minutes, feel free to contact our support team.
            </div>
            <div className="w-full m-auto">
              <button
                onClick={() => navigate("/login")}
                className="mt-7 w-full m-auto bg-[#1c3775] text-white px-7 py-2 font-normal rounded-full text-lg max-md:text-[14px]"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationDone;
