import React from "react";
import { useNavigate } from "react-router-dom";

function FooterDashboard() {
  const navigate = useNavigate();

  const navLinks2 = [
    { text: "Terms & Conditions", to: "/terms-and-conditions" },
    { text: "Anti-Bribery & Corruption Policy", to: "/anti-bribery-policy" },
    { text: "Privacy Policy", to: "/privacy" },
  ];

  const handleClick = (to) => {
    window.open(to, "_blank");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="flex justify-between items-center self-stretch px-8 py-2  w-full text-neutral-400 bg-white border-t border-neutral-300 border-solid  max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-0 max-md:flex-wrap max-lg:flex-col justify-center max-md:align-center w-full">
          <div className="flex flex-col flex-1 justify-end max-w-[1366px] max-md:max-w-full">
            <div className="flex gap-1 items-center justify-between w-full text-md max-lg:flex-wrap max-lg:px-5 max-md:flex-col-reverse max-md:px-0 max-md:w-full">
              <div className="max-md:text-[12px] max-md:text-center">
                © 2024 Full ID Check All Rights Reserved
              </div>
              <div className="flex justify-between gap-3 flex-row py-1.5 leading-[145%] max-lg:text-center max-lg:m-auto max-md:w-full">
                {navLinks2.map((link, index) => (
                  <div
                    key={index}
                    className="cursor-pointer my-auto max-md:text-[10px]"
                    onClick={() => handleClick(link.to)}
                  >
                    {link.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterDashboard;