import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { getInterviewSchedule } from "../../api/apiCall";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../actions";
function PendingApproval() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [link,setLink] = useState('');
  const [schedule,setSchedule] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoBackToHomepage = () => {
    navigate("/");
  };

  useEffect(() => {
    getInterviewDetails();
  },[]);

  const getInterviewDetails = async () => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
        },
      };

      const response = await getInterviewSchedule(data);
      if (response.status === 200) {
        const result = response.data
        const sched = moment(result.scheduled_at).format('MMM D, YYYY | hh:mm A');
        setLink(result?.join_url);
        setSchedule(sched);
      }
    } catch (error) {
      console.log("Error getting your interview details: ", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleGoBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="bg-[#F9FAFB] py-14 h-screen">
      <div className="h-[30vh] px-10 max-md:px-5">
        <div className="flex justify-center items-center flex-col mx-auto mt-20">
          <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
            <div className="flex items-center justify-center gap-3 max-md:flex-col max-md:text-center">
              <FaRegCheckCircle className="text-[36px] text-devRegDarkBlue" />
              <div className="flex-1 text-[30px] font-bold text-[#1c3775] max-md:text-[24px]">
                Pending Approval
              </div>
            </div>
            <div className="mt-4 text-center font-normal max-md:text-sm flex flex-col items-center gap-y-4">
              <p className="leading-[24px] text-center">You have successfully scheduled your interview. Please ensure you sign in to your account before your scheduled interview time.</p>
              {/* <p className="leading-[24px] text-center">At the scheduled time, you will be interviewed by an Admin or a Manager. When you log in, you will be directed immediately to the interview box, where you must click "Interview Now"  and wait to begin.</p> */}
              
              {schedule && <p className="leading-[24px] text-center">Scheduled on: <span className="font-semibold text-blue-600">{schedule}</span></p>}
              {link && <p className="leading-[24px] text-center">
                <Link 
                      className="cursor-pointer text-blue-600 underline" 
                      to={link} 
                      target="_blank"
                  >
                    Interview Now
                  </Link>
              </p>}
              <p className="leading-[24px] text-center">Good Luck with your interview we are excited that you will soon be a verified member.</p>
            </div>
            <div className="w-full m-auto">
              <button
                onClick={handleGoBackToHomepage}
                className="mt-7 w-full m-auto bg-[#1c3775] text-white px-7 py-2 font-normal rounded-full text-lg max-md:text-[14px]"
              >
                Back to Homepage
              </button>
              <button
                onClick={handleGoBackToLogin}
                className="underline mt-3 w-full m-auto text-gray-700 font-normal text-lg max-md:text-[14px]"
              >
                Back to Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingApproval;
