import React, { useState, useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  checkEmailVerification,
} from "../../api/apiCall";

function RegistrationSuccess() {

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleCheckEmailVerification();
    }, 7000);

    return () => clearInterval(intervalId);

  }, []);

  const handleCheckEmailVerification = async () => {
    try {
      const response = await checkEmailVerification();
      if (+response.data?.is_verified === 1) {
        const sn = response.data?.check_registration_status?.serial_number;
        navigate(`/personal-information?sn=${sn}`);
      }
    }
    catch (error) {
      console.log("Error checking email verification: ", error);
    }
  }

  return (
    <div className="bg-[#F9FAFB] py-14 h-screen">
      <div className="h-[30vh] px-10 max-md:px-5">
        <div className="flex justify-center items-center flex-col mx-auto mt-20">
          <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
            <div className="flex items-center justify-center gap-3 max-md:text-center max-md:flex-col">
              <FaRegCheckCircle className="text-[45px] text-[#00A018]" />
              <div className="flex-1 text-[30px] font-bold text-[#1c3775] max-md:text-[24px]">
                Thank You for your Registration!
              </div>
            </div>
            <div className="mt-4 text-center font-normal max-md:text-sm text-[#374151]">
              Thank you for signing up here at FullIDCheck. Please check your email for the
              confirmation of this registration.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationSuccess;
