import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../actions";
import axios from "axios";

const GoogleAuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  useEffect(() => {
    const handleGoogleCallback = async () => {
      dispatch(setIsLoading(true));

      try {
        // Extract token and userId from the URL
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token");
        const userId = queryParams.get("id");

        if (!token || !userId) {
          setError("Authentication failed. Missing token or user ID.");
          return;
        }

        // Simulate fetching user data with the token (replace with actual API call if needed)
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/user`,
          {
            headers: {
              // 'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (response.status === 200) {
          const {
            user,
            two_factor,
            features,
            languages,
            work_histories,
            portfolios,
            skills,
            educations,
            awards,
          } = response.data;

          // Store data in local storage
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("features", JSON.stringify(features));
          localStorage.setItem("languages", JSON.stringify(languages));
          localStorage.setItem("work_histories", JSON.stringify(work_histories));
          localStorage.setItem("portfolios", JSON.stringify(portfolios));
          localStorage.setItem("skills", JSON.stringify(skills));
          localStorage.setItem("educations", JSON.stringify(educations));
          localStorage.setItem("awards", JSON.stringify(awards));
          localStorage.setItem("token", token);

          const expiryTime = Date.now() + 60 * 60 * 1000; // 1 hour expiry
          localStorage.setItem("sessionExpiryTime", expiryTime);

          let nextRoute = "/dashboard/edit-profile?tab=overview";
  
          if (user.check_registration_status?.is_profile_info_done === 0) {
            nextRoute = "/personal-information";
          } else if (user.check_registration_status?.is_paid === 0) {
            nextRoute = "/payment";
          } else if (user.check_registration_status?.is_document_uploaded === 0) {
            nextRoute = "/upload-documents";
          } else if (user.check_registration_status?.is_face_recognized === 0) {
            if(!localStorage.getItem("isMinor")){
              nextRoute = "/face-recognition";
            }
            else{
              nextRoute = "/face-recognition-minor";
            }
          } else if (user.check_registration_status?.is_interviewing === 0) {
            nextRoute = "/interview-process";
          } else if (user.check_registration_status?.registration_status === 0) {
            // Set nextRoute if applicable
          } else if (user.check_registration_status?.is_verified === 0) {
            // Set nextRoute if applicable
          } else if (user.check_registration_status?.has_access_key) {
            nextRoute = "/enter-access-key";
          } else if (!two_factor) {
            nextRoute = "/qr-scan";
          }
          
           localStorage.setItem('isLoggedIn', true);
           dispatch(setIsLoggedIn(true));
    
          // console.log("isLoggedIn:", localStorage.getItem('isLoggedIn'));
          // console.log(localStorage)
          localStorage.setItem("messages_count", JSON.stringify({theRegister: 0, devreg: 0, admin: 0}));
          navigate(nextRoute);
        } else {
          setError("Authentication failed. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);

        if (error.response) {
          setError(error.response.data.message || "Authentication failed.");
        } else {
          setError("An error occurred. Please try again later.");
        }
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    handleGoogleCallback();
  }, [dispatch, navigate]);

  return (
    <div className="auth-callback">
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div>Authenticating... Please wait.</div>
      )}
    </div>
  );
};

export default GoogleAuthCallback;
