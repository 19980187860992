// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./feature/store";
// import store from "./store";
import LocaleProvider from "./LocaleProvider";

import './assets/fonts/Inter.woff2';

// localStorage.clear();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocaleProvider />
        {/* <App /> */}
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
