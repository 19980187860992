import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoggedIn, setIsLoading } from "../../actions";
import { login } from "../../api/apiCall";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state
  const [fullName, setFullName] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const [toggleEye, setToggleEye] = useState(false);
  const [error, setError] = useState("");

  // Toggle password visibility
  const handleToggleEye = () => setToggleEye((prev) => !prev);

  // Navigation handlers
  const handleForgotPassword = () => navigate("/forgot-password");
  const handleSignUp = () => navigate("/register");

  // Helper: Determine the next route and whether the user should be marked as logged in
  const getNextRoute = (user) => {
    
    const status = user.check_registration_status || {};
    if (status.is_profile_info_done === 0) {
      return { route: "/personal-information", loggedIn: true, dashboard: false, };
    }
    if (status.is_paid === 0) {
      return { route: "/payment", loggedIn: true, dashboard: false, };
    }
    if (status.is_document_uploaded === 0) {
      return { route: "/upload-documents", loggedIn: true, dashboard: false, };
    }
    if (status.is_face_recognized === 0) {
      const isMinor = localStorage.getItem("isMinor");
      return { route: isMinor ? "/face-recognition-minor" : "/face-recognition", loggedIn: true, dashboard: false, };
    }
    if (status.is_interviewing === 0) {
      return { route: "/interview-process", loggedIn: true, dashboard: false, };
    }
    if ((status?.registration_status || user.registration_status) !== "APPROVED") {
      // return { route: "/interview-process", loggedIn: true };
      return { route: "/pending-approval", loggedIn: false, dashboard: false, };
    }
    if (status.is_verified === 0) {
      return { route: "/pending-approval", loggedIn: false, dashboard: false, };
    }
    if (status.has_access_key) {
      return { route: "/enter-access-key", loggedIn: true, dashboard: false, };
    }
    if (user.registration_status !== "APPROVED") {
      // return { route: "/interview-process", loggedIn: true };
      return { route: "/pending-approval", loggedIn: false, dashboard: false, };
    }
    return { route: "/dashboard/messages", loggedIn: true, dashboard: true, };
  };

  // Login function
  const loginUser = async () => {
    dispatch(setIsLoading(true));
    try {
      const sendData = { email: fullName, password: loginPass };
      const response = await login(sendData);
      
      if (response.status === 200) {
        const {
          token,
          user,
          features,
          languages,
          work_histories,
          portfolios,
          skills,
          educations,
          awards,
        } = response.data;

        // Save user data and token
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("features", JSON.stringify(features));
        localStorage.setItem("languages", JSON.stringify(languages));
        localStorage.setItem("work_histories", JSON.stringify(work_histories));
        localStorage.setItem("portfolios", JSON.stringify(portfolios));
        localStorage.setItem("skills", JSON.stringify(skills));
        localStorage.setItem("educations", JSON.stringify(educations));
        localStorage.setItem("awards", JSON.stringify(awards));
        localStorage.setItem("token", token);
        localStorage.setItem("sessionExpiryTime", Date.now() + 60 * 60 * 1000); // 1 hour expiry
        localStorage.setItem(
          "messages_count",
          JSON.stringify({ theRegister: 0, devreg: 0, admin: 0 })
        );

        const { route, loggedIn, dashboard } = getNextRoute(user);

        // If user is not fully approved, mark as not logged in and navigate immediately
        if (!loggedIn) {
          localStorage.setItem("isLoggedIn", false);
          dispatch(setIsLoggedIn(false));
          navigate(route);
          return;
        }

        // Mark user as logged in
        if(dashboard){
          localStorage.setItem("isLoggedIn", true);
          dispatch(setIsLoggedIn(true));
        }

        navigate(route);
      } else {
        setError("Login failed. Please try again.");
      }
    } catch (err) {
      console.error("Login error:", err);
      setError(err.response?.data?.message || "An error occurred. Please try again later.");
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleLogin = () => {
    loginUser();
  };

  const resendVerificationEmail = async () => {
    alert("verify");
    // Implement verification email resend logic if needed.
  };

  return {
    fullName,
    setFullName,
    loginPass,
    setLoginPass,
    toggleEye,
    handleToggleEye,
    handleForgotPassword,
    handleSignUp,
    handleLogin,
    error,
    resendVerificationEmail,
    logoImg: "", // Update with your logo image URL if needed.
  };
};

export default useLogin;
