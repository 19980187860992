import React, { useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { revalidateSession } from "../../api/apiCall";
function Thankyou() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const session_token = urlParams.get("session_token") || '';

  useEffect(() => {
    const validateSession = async () => {
      if(session_token){
        try{
          const data = {
            session_token: session_token,
          }
  
          const response = await revalidateSession(data);
  
          const user = response?.data?.user;
          if(user){
            localStorage.setItem('user',JSON.stringify(user));
          }
        }catch(errors){
          console.log('SESSION_REVALIDATION',errors);
        }
      }
    };
    
    validateSession();
    
  },[session_token]);

  const handleContinueRegistartion = () => {
    navigate("/upload-documents");
  };

  return (
    <div className="bg-[#F9FAFB] py-14 h-screen">
      <div className="h-[30vh] px-10 max-md:px-5">
        <div className="flex justify-center items-center flex-col mx-auto mt-20">
          <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
            <div className="flex items-center justify-center gap-3 max-md:flex-col max-md:text-center">
              <FaRegCheckCircle className="text-[36px] text-devRegDarkBlue" />
              <div className="flex-1 text-[30px] font-bold text-[#1c3775] max-md:text-[24px]">
                Thank you for your Payment!
              </div>
            </div>
            <div className="mt-4 text-center font-normal max-md:text-sm">
              Thank you for your payment. Please check your email for
              confirmation of this payment.
            </div>
            <div className="w-full m-auto">
              <button
                onClick={handleContinueRegistartion}
                className="mt-7 w-full m-auto bg-[#1c3775] text-white px-7 py-2 font-normal rounded-full text-lg max-md:text-[14px]"
              >
                Continue with Registration
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;
